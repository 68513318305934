import React, { useState, useEffect } from "react";
import { NUMBER_OF_TOP_RESULTS } from "src/components/calculatorUtils";
import { ICalculationResult } from "./ICalculationResult";
import styles from "src/components/ResultsTable1Card.module.css";
import { ExternalLink } from "react-feather";

interface ResultsTableProps {
  results: ICalculationResult[];
}

const ResultsTable1Card: React.FC<ResultsTableProps> = ({ results }) => {
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const [sortedResults, setSortedResults] = useState<ICalculationResult[]>([]);
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [showAllResults, setShowAllResults] = useState(false);

  useEffect(() => {
    const sorted = [...results].sort((a, b) => {
      let aValue: any;
      let bValue: any;

      if (sortColumn === "cardName") {
        aValue =
          `${a.cards[0].institutionName} ${a.cards[0].cardName}`.toLowerCase();
        bValue =
          `${b.cards[0].institutionName} ${b.cards[0].cardName}`.toLowerCase();
      } else {
        aValue = a[sortColumn as keyof ICalculationResult];
        bValue = b[sortColumn as keyof ICalculationResult];
      }

      if (sortOrder === "asc") {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });
    setSortedResults(sorted);
  }, [results, sortColumn, sortOrder]);

  const handleSort = (column: string) => {
    let newSortOrder: "asc" | "desc" = "asc";
    if (sortColumn === column && sortOrder === "asc") {
      newSortOrder = "desc";
    }
    setSortColumn(column);
    setSortOrder(newSortOrder);
  };

  const renderSortIcon = (column: string) => {
    if (sortColumn === column) {
      return sortOrder === "asc" ? " ▽▲" : " ▼△";
    }
    return " ▽△";
  };

  const handleRowClick = (id: string) => {
    if (expandedRows.includes(id)) {
      setExpandedRows(expandedRows.filter((rowId) => rowId !== id));
    } else {
      setExpandedRows([...expandedRows, id]);
    }
  };

  return (
    <div className={styles.tableContainer}>
      <button
        className={styles.button}
        onClick={() => setShowAllResults(!showAllResults)}
      >
        {showAllResults
          ? "Show Top " + NUMBER_OF_TOP_RESULTS + " Results"
          : "Show All Results"}
      </button>
      <table className={styles.table}>
        <thead>
          <tr>
            <th
              onClick={() => handleSort("cardName")}
              className={styles.sortable}
            >
              Card {renderSortIcon("cardName")}
            </th>
            <th
              onClick={() => handleSort("totalMonthsToDesiredPoints")}
              className={styles.sortable}
            >
              <span style={{ whiteSpace: "nowrap" }}>
                Months{renderSortIcon("totalMonthsToDesiredPoints")}
              </span>
            </th>
            <th
              onClick={() => handleSort("totalFees")}
              className={styles.sortable}
            >
              Fees {renderSortIcon("totalFees")}
            </th>
            <th
              onClick={() => handleSort("combinedScore")}
              className={styles.sortable}
            >
              Overall Score {renderSortIcon("combinedScore")}
            </th>
          </tr>
        </thead>
        <tbody>
          {(showAllResults
            ? sortedResults
            : sortedResults.slice(0, NUMBER_OF_TOP_RESULTS)
          ).map((result) => (
            <React.Fragment key={result.cards[0].id}>
              <tr
                className={styles.row}
                onClick={() => handleRowClick(result.cards[0].id)}
              >
                <td data-label="Card" className={styles.cardName}>
                  {expandedRows.includes(result.cards[0].id) ? "▼ " : "▶ "}
                  {result.cards[0].institutionName} {result.cards[0].cardName}{" "}
                  <a
                    href={result.cards[0].url}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <ExternalLink size={"15"} />
                  </a>
                </td>
                <td
                  data-label="Months to Earn Points"
                  className={styles.months}
                >
                  {result.totalMonthsToDesiredPoints}
                </td>
                <td data-label="Total Fees" className={styles.fees}>
                  ${result.totalFees}.00
                </td>
                <td data-label="Overall Score" className={styles.score}>
                  {result.combinedScore}
                </td>
              </tr>
              {expandedRows.includes(result.cards[0].id) && (
                <tr
                  key={`${result.cards[0].id}-details`}
                  className={`${styles.detailsRow} ${styles.expandableRow}`}
                >
                  <td colSpan={4}>
                    <div className={styles.detailsContainer}>
                      <p>
                        <b>Initial Bonus Points:</b>{" "}
                        {result.cards[0].bonusPoints}
                      </p>
                      <p>
                        <b>Minimum Spend:</b> $
                        {result.cards[0].minimumSpendInBonusPeriod}.00
                      </p>
                      <p>
                        <b>Months to Spend:</b> {result.cards[0].bonusPeriod}
                      </p>
                      <p
                        className={
                          result.totalMonthsToDesiredPoints < 12
                            ? styles.italics
                            : undefined
                        }
                      >
                        <b>Bonus Points 2nd Year:</b>{" "}
                        {result.cards[0].bonusPoints2ndYear}
                      </p>
                      <p>
                        <b>Earn Rate:</b> {result.cards[0].earnRate} pt/$
                      </p>
                      <p>
                        <b>Annual Fee 1st Year:</b> $
                        {result.cards[0].annualFeeFirstYear}.00
                      </p>

                      <p
                        className={
                          result.totalMonthsToDesiredPoints < 12
                            ? styles.italics
                            : undefined
                        }
                      >
                        <b>Annual Fee Subsequent Years:</b> $
                        {result.cards[0].annualFeeSubsequentYears}.00
                      </p>
                      <p>
                        <b>Total Spend Needed:</b> ${result.totalSpend}.00
                      </p>
                      <p>
                        <b>Points Earned Through Spend:</b>{" "}
                        {result.pointsEarned}
                      </p>
                      <p>
                        <b>Total Points:</b> {result.totalPoints}
                      </p>
                      <p>
                        <b>Total Cost:</b> ${result.totalFees}.00
                      </p>
                      <p>
                        <b>Additional Info:</b> {result.cards[0].additionalInfo}
                      </p>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ResultsTable1Card;
