import React, { useEffect, useState } from "react";
import { calculatePoints1Card } from "src/components/oneCardCalculator";
import ResultsTable1Card from "src/components/ResultsTable1Card";
import { cards } from "./cards";
import { ICalculationInput } from "./ICalculationInput";
import { ICalculationResult } from "./ICalculationResult";
import styles from "./PointsCalculator.module.css";
import PointsCalculatorForm from "./PointsCalculatorForm";
import ResultsTable2Cards from "./ResultsTable2Cards";
import ResultsTable3Cards from "./ResultsTable3Cards";
import { calculatePoints3Cards } from "./threeCardCalculator";
import { calculatePoints2Cards } from "./twoCardCalculator";

const PointsCalculator: React.FC = () => {
  const [destination, setDestination] = useState(
    localStorage.getItem("destination") || ""
  );
  const [desiredPoints, setDesiredPoints] = useState(
    localStorage.getItem("desiredPoints") || "120000"
  );
  const [monthlySpend, setMonthlySpend] = useState(
    localStorage.getItem("monthlySpend") || "2000"
  );
  const [numberOfCards, setNumberOfCards] = useState(
    localStorage.getItem("numberOfCards") || "1"
  );
  const [results1Card, setResults1Card] = useState<ICalculationResult[]>([]);
  const [results2Cards, setResults2Cards] = useState<ICalculationResult[]>([]);
  const [results3Cards, setResults3Cards] = useState<ICalculationResult[]>([]);
  useEffect(() => {
    localStorage.setItem("destination", destination);
    localStorage.setItem("desiredPoints", desiredPoints);
    localStorage.setItem("monthlySpend", monthlySpend);
    localStorage.setItem("numberOfCards", numberOfCards);
  }, [destination, desiredPoints, monthlySpend, numberOfCards]);

  const runCalculation = () => {
    const input: ICalculationInput = {
      desiredPoints: Number(desiredPoints),
      monthlyCreditCardSpend: Number(monthlySpend),
      cards,
      numberOfCards: Number(numberOfCards),
    };
    const calculationResults1Card = calculatePoints1Card(input);
    setResults1Card(calculationResults1Card);
    if (Number(numberOfCards) > 1) {
      const calculationResults2Cards = calculatePoints2Cards(input);
      console.log("calculationResults2Cards", calculationResults2Cards);
      setResults2Cards(calculationResults2Cards);
    }
    if (Number(numberOfCards) > 2) {
      const calculationResults3Cards = calculatePoints3Cards(input);
      console.log("calculationResults3Cards", calculationResults3Cards);
      setResults3Cards(calculationResults3Cards);
    }
  };
  return (
    <div className={styles.container}>
      <PointsCalculatorForm
        desiredPoints={desiredPoints}
        setDesiredPoints={setDesiredPoints}
        monthlySpend={monthlySpend}
        setMonthlySpend={setMonthlySpend}
        destination={destination}
        setDestination={setDestination}
        numberOfCards={numberOfCards}
        setNumberOfCards={setNumberOfCards}
        runCalculation={runCalculation}
      />
      {results1Card.length !== 0 && (
        <>
          <div className={styles.info}>
            <p>Click on a row to see more details.</p>
            <p>
              The overall score represents our recommendations. The score
              combines the fees and the months into s single score form 0-1000.
              The cards with the best offer in both categories get the highest
              score.
            </p>
          </div>
          <h2 className={styles.heading}>Single Credit Card</h2>
          <ResultsTable1Card results={results1Card} />
        </>
      )}
      {results2Cards.length !== 0 && (
        <div>
          <h2 className={styles.heading}>Two Credit Cards</h2>
          <ResultsTable2Cards results={results2Cards} />
        </div>
      )}
      {results3Cards.length !== 0 && (
        <div>
          <h2 className={styles.heading}>Three Credit Cards</h2>
          <ResultsTable3Cards results={results3Cards} />
        </div>
      )}
      <p className={styles.finePrint + " mt-2"}>
        <b>Disclaimer:</b> The information provided is for general information
        purposes only and is not intended to be personalised financial advice.
        The results are based on the information provided and are not indicative
        of the credit card provider's terms and conditions.
      </p>
      <div className={"d-flex"}>
        <p className={"ms-auto " + styles.finePrint}>
          Card data has last been reviewed on <b>08.08.2024</b>. Mistakes are
          possible, we're just human. Please double-check with the card issuer.
        </p>
      </div>
    </div>
  );
};

export default PointsCalculator;
