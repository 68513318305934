import { ICreditCard } from "./ICreditCard";

export const cards: ICreditCard[] = [
  {
    id: "d7c7b1f3-0b6c-4d94-947a-45f9cc764df7",
    institutionName: "ANZ",
    cardName: "Frequent Flyer Black",
    url: "https://www.anz.com.au/personal/credit-cards/frequent-flyer-black/",
    issuer: "Visa",
    bonusPoints: 90000,
    minimumSpendInBonusPeriod: 5000,
    bonusPeriod: 3,
    bonusPoints2ndYear: 40000,
    earnRate: 1,
    annualFeeFirstYear: 425,
    annualFeeSubsequentYears: 425,
    additionalInfo:
      "$200 back, $15,000 minimum credit limit, Earn 1 Qantas Point for every $1 up to and including $7,500, Earn 0.5 Qantas Points for every $1 over $7,500, complimentary travel insurance; insurance on purchases; 2 Qantas Club Lounge Invitations per year",
  },
  {
    id: "c39f3d8c-8e67-460d-8e1e-96d8d1b6b40e",
    institutionName: "ANZ",
    cardName: "Frequent Flyer Platinum",
    url: "https://www.anz.com.au/personal/credit-cards/frequent-flyer-platinum/",
    issuer: "Visa",
    bonusPoints: 75000,
    minimumSpendInBonusPeriod: 2500,
    bonusPeriod: 3,
    bonusPoints2ndYear: 0,
    earnRate: 0.75,
    annualFeeFirstYear: 295,
    annualFeeSubsequentYears: 295,
    additionalInfo:
      "$6,000 minimum credit limit; $100 back; Uncapped Qantas Points earning potential, Complimentary Travel Insurance; insurance on purchases",
  },
  {
    id: "1052ee47-b5f8-41ec-bc4d-ea6a51d4b837",
    institutionName: "CommBank",
    cardName: "Smart Awards",
    url: "https://www.commbank.com.au/credit-cards/smart-awards.html",
    issuer: "Mastercard",
    bonusPoints: 50000,
    minimumSpendInBonusPeriod: 6000,
    bonusPeriod: 4,
    bonusPoints2ndYear: 0,
    earnRate: 0.6,
    annualFeeFirstYear: 288,
    annualFeeSubsequentYears: 288,
    additionalInfo:
      "Additional 75000 Commbank Awards points; 0.8 pts/$ on highest eligible purchase; no international transaction fees; no additional cardholder fee; international travel insurance when you spend at least $500 in a single transaction on prepaid travel using your card and activate before you travel; no monthly fee if you spend at least $2000",
  },
  {
    id: "7be7e122-4ff7-4793-9e9c-7db899a7f7bc",
    institutionName: "CommBank",
    cardName: "Ultimate Awards",
    url: "https://www.commbank.com.au/credit-cards/ultimate.html",
    issuer: "MasterCard",
    bonusPoints: 60000,
    minimumSpendInBonusPeriod: 14000,
    bonusPeriod: 4,
    bonusPoints2ndYear: 0,
    earnRate: 0.8,
    annualFeeFirstYear: 480,
    annualFeeSubsequentYears: 480,
    additionalInfo:
      "$35 monthly fee; $60 annual Qantas opt-in fee; 1.2 pts for international transactions; Access to international travel insurance when you spend at least $500 in a single transaction on prepaid travel using your card and activate before you travel; No monthly fee if you spend at least $4000; No additional cardholder fee; no international transaction fees; Two complimentary airport lounge visits",
  },
  {
    id: "3b5e48aa-2a9e-414a-b4e3-1b2a9ff32b20",
    institutionName: "Citi",
    cardName: "Premier Qantas",
    url: "https://www1.citibank.com.au/credit-cards/citi-premier-qantas",
    issuer: "Mastercard",
    bonusPoints: 70000,
    minimumSpendInBonusPeriod: 6000,
    bonusPeriod: 3,
    bonusPoints2ndYear: 30000,
    earnRate: 0.5,
    annualFeeFirstYear: 350,
    annualFeeSubsequentYears: 175,
    additionalInfo:
      "Minimum credit limit $10,000; 0.5 pts/$ on domestic spend, 1pt/$ on eligible online transactions; complementary Additional cardholders; Complimentary Travel Insurance, mobile phone insurance; Two complimentary airport lounge visits; $175 annual fee in subsequent years if you spend $48,000 or more on eligible purchases or cash advances in the previous year",
  },
  {
    id: "8a0bf3e5-e26e-4a55-982b-9d54deff13de",
    institutionName: "G&C Mutual Bank",
    cardName: "Platinum Visa Credit",
    url: "https://www.gcmutual.bank/products/cards/platinum-visa-credit-card/",
    issuer: "Visa",
    bonusPoints: 30000,
    minimumSpendInBonusPeriod: 5000,
    bonusPeriod: 3,
    bonusPoints2ndYear: 10000,
    earnRate: 1,
    annualFeeFirstYear: 299,
    annualFeeSubsequentYears: 299,
    additionalInfo:
      "Minimum credit limit $6,000; Earn rate capped at 20000 pts; annual 10000 bonus points; need to keep card open at least 90 days; complimentary Frequent Flyer membership; additional cardholder at no extra cost; Platinum Visa Concierge",
  },
  {
    id: "ddfa3e32-6eb8-458a-9425-3b6b5ac2c3f6",
    institutionName: "HSBC",
    cardName: "Platinum Qantas",
    url: "https://www.hsbc.com.au/credit-cards/products/platinum-qantas/",
    issuer: "Visa",
    bonusPoints: 0,
    minimumSpendInBonusPeriod: 0,
    bonusPeriod: 0,
    bonusPoints2ndYear: 0,
    earnRate: 1.5,
    annualFeeFirstYear: 0,
    annualFeeSubsequentYears: 299,
    additionalInfo:
      "Earn 1.5 Qantas Points per $1 spent on eligible purchases in the first year, up to $120,000 annually. Subsequent years, earn 1 Qantas Point per $1 spent, up to $120,000 annually. Complimentary Qantas Frequent Flyer membership; Complimentary travel insurance; 0% p.a. Balance Transfer for 12 months, a 2% balance transfer fee applies. Additional cardholders for no extra fee",
  },
  {
    id: "d73d4d7e-b79b-40ec-8a4e-5998f6f914d7",
    institutionName: "NAB",
    cardName: "Qantas Rewards Premium",
    url: "https://www.nab.com.au/personal/credit-cards/qantas-rewards/nab-qantas-rewards-premium-card",
    issuer: "Visa",
    bonusPoints: 60000,
    minimumSpendInBonusPeriod: 2000,
    bonusPeriod: 2,
    bonusPoints2ndYear: 10000,
    earnRate: 0.66,
    annualFeeFirstYear: 150,
    annualFeeSubsequentYears: 250,
    additionalInfo:
      "Complimentary Travel Insurance; purchase insurance; mobile phone insurance; Free Qantas Frequent Flyer membership; Add an additional cardholder at no extra cost; 0% balance transfer (BT) rate for 12 months 3% BT fee applies.",
  },
  {
    id: "6a96c53f-0f53-47a6-98c7-7f9389568a6a",
    institutionName: "NAB",
    cardName: "Qantas Rewards Signature",
    url: "https://www.nab.com.au/personal/credit-cards/qantas-rewards/nab-qantas-rewards-signature-card",
    issuer: "Visa",
    bonusPoints: 90000,
    minimumSpendInBonusPeriod: 3000,
    bonusPeriod: 2,
    bonusPoints2ndYear: 30000,
    earnRate: 1,
    annualFeeFirstYear: 295,
    annualFeeSubsequentYears: 395,
    additionalInfo:
      "Minimum credit limit$15,000; Free Qantas Frequent Flyer membership; 1 point per $1 spent up to $5,000, then 0.5 points per $1 from $5,001 to $20,000. Complimentary Travel, purchases, mobile Insurance; 0% pa balance transfer for 12 months, 3% BT fee, capped points at $20,000 per statement period",
  },
  {
    id: "ea6693d8-5f96-4771-9c25-3a1391383941",
    institutionName: "Qantas",
    cardName: "American Express Discovery",
    url: "https://www.americanexpress.com/au/credit-cards/qantas-discovery-card/",
    issuer: "American Express",
    bonusPoints: 0,
    minimumSpendInBonusPeriod: 0,
    bonusPeriod: 0,
    bonusPoints2ndYear: 0,
    earnRate: 0.75,
    annualFeeFirstYear: 0,
    annualFeeSubsequentYears: 0,
    additionalInfo:
      "Up to 4 Additional Cardholders for no extra fee, Card Refund and Purchase Cover",
  },
  {
    id: "70d9f96c-4b7c-4ea6-94c2-bf0b6b1d2301",
    institutionName: "Qantas",
    cardName: "American Express Premium",
    url: "https://www.americanexpress.com/au/credit-cards/qantas-premium-card/",
    issuer: "American Express",
    bonusPoints: 20000,
    minimumSpendInBonusPeriod: 3000,
    bonusPeriod: 3,
    bonusPoints2ndYear: 0,
    earnRate: 1,
    annualFeeFirstYear: 249,
    annualFeeSubsequentYears: 249,
    additionalInfo:
      "Earn 1 Qantas Point per $1 spent on eligible purchases. 1 additional Qantas Point per $1 on selected Qantas products and services in Australia. 0.5 Qantas Points per $1 spent at government bodies in Australia. Complimentary Travel insurance; Includes 2 Qantas Club invitations each Card Member year.",
  },
  {
    id: "e2b1f230-1ac4-4f2a-8c6e-8c3d7d9d7f82",
    institutionName: "Qantas",
    cardName: "American Express Ultimate",
    url: "https://www.americanexpress.com/au/credit-cards/qantas-ultimate-card/",
    issuer: "American Express",
    bonusPoints: 70000,
    minimumSpendInBonusPeriod: 3000,
    bonusPeriod: 3,
    bonusPoints2ndYear: 20000,
    earnRate: 1.25,
    annualFeeFirstYear: 450,
    annualFeeSubsequentYears: 450,
    additionalInfo:
      "Up to 4 Additional Cardholders for no extra fee, Two complimentary entries to The Centurion Lounge, plus two Qantas Club Lounge. Earn 1.25 Qantas Points earned per $1 on eligible everyday purchases. 2.25 Qantas Points per $1 spent on selected Qantas products and services in Australia. 0.5 Qantas Point per $1 at government bodies in Australia. Includes $450 Qantas Travel Credit each year.",
  },
  {
    id: "6f0f7e68-8c22-46d3-a58d-40c25be3b556",
    institutionName: "Qantas",
    cardName: "Premier Platinum",
    url: "https://www.qantasmoney.com/credit-cards/premier-platinum",
    issuer: "Mastercard",
    bonusPoints: 70000,
    minimumSpendInBonusPeriod: 3000,
    bonusPeriod: 3,
    bonusPoints2ndYear: 0,
    earnRate: 1,
    annualFeeFirstYear: 349,
    annualFeeSubsequentYears: 399,
    additionalInfo:
      "Additional 30,000 bonus points if you haven’t earned Qantas Points with a credit card in the last 12 months. Earn 1 Qantas Point per whole AU$1 on Domestic Spend up to $10,000 per statement period and 0.5 thereafter. 1.5 Qantas Points per whole AU$1 equivalent on international spend plus 1 additional Qantas Point per whole AU$1 on selected Qantas spend. 0% P.A For 12 months on Balance Transfers. A 2% Balance Transfer fee applies; 2 Qantas club lounge invitations",
  },
  {
    id: "8b85ef70-754a-4f9f-9f9e-61c0ff61cc97",
    institutionName: "Qantas",
    cardName: "Premier Titanium",
    url: "https://www.qantasmoney.com/credit-cards/premier-titanium",
    issuer: "Mastercard",
    bonusPoints: 150000,
    minimumSpendInBonusPeriod: 5000,
    bonusPeriod: 3,
    bonusPoints2ndYear: 0,
    earnRate: 1.25,
    annualFeeFirstYear: 1200,
    annualFeeSubsequentYears: 1200,
    additionalInfo:
      "Need to earn at least $200,000 per year; 10% Savings on the base fare of eligible Qantas flights for up to 2 travellers twice per year; Earn 1.25 Qantas Points per $1 up to $12,500 per statement period and 0.5 thereafter. 2 Qantas Points per whole AU$1 equivalent on international spend (uncapped). 20% bonus Status Credits; Two complimentary Qantas First Lounge Invitations and two additional Lounge Invitations per year",
  },
  {
    id: "7a3211c0-3c31-42d1-9f74-0ecb16acbebf",
    institutionName: "St.George",
    cardName: "Amplify Qantas Signature",
    url: "https://www.qantas.com/au/en/frequent-flyer/member-specials/st-george-amplify-signature.html",
    issuer: "Visa",
    bonusPoints: 80000,
    minimumSpendInBonusPeriod: 6000,
    bonusPeriod: 3,
    bonusPoints2ndYear: 0,
    earnRate: 0.75,
    annualFeeFirstYear: 370,
    annualFeeSubsequentYears: 370,
    additionalInfo:
      "$295 annual card fee plus an annual $75 Qantas Rewards Fee; 2 Qantas Club Lounge Invitations per year, Complimentary Frequent Flyer Membership; Complimentary Travel Insurance; extended warranty and purchase protection for up to 4 months",
  },
  {
    id: "b0830e50-8f4a-4d60-b07d-6e8a3147f79f",
    institutionName: "St.George",
    cardName: "Amplify Qantas Platinum",
    url: "https://www.qantas.com/au/en/frequent-flyer/member-offers/st-george-amplify-platinum-credit-card.html",
    issuer: "Visa",
    bonusPoints: 50000,
    minimumSpendInBonusPeriod: 3000,
    bonusPeriod: 3,
    bonusPoints2ndYear: 0,
    earnRate: 0.5,
    annualFeeFirstYear: 174,
    annualFeeSubsequentYears: 199,
    additionalInfo:
      "$6,000 Minimum credit limit; $99 card fee for the first year ($124 thereafter) plus an annual $75 Qantas Rewards Fee; Complimentary additional cardholder; Complimentary Travel Insurance; extended warranty and purchase protection for up to 4 months",
  },
  {
    id: "035f35a5-9c0e-4bf1-b8ed-4d61c7d7e2b1",
    institutionName: "Suncorp",
    cardName: "Clear Options Platinum Credit",
    url: "https://www.suncorpbank.com.au/bank-and-save/credit-cards/platinum-qantas.html",
    issuer: "Visa",
    bonusPoints: 40000,
    minimumSpendInBonusPeriod: 4500,
    bonusPeriod: 3,
    bonusPoints2ndYear: 0,
    earnRate: 0.5,
    annualFeeFirstYear: 178,
    annualFeeSubsequentYears: 178,
    additionalInfo:
      "Travel Insurance; extended warranty insurance; 0% p.a. for 12 months on balance transfers",
  },
  {
    id: "8e61b4e5-5f0d-4aa4-b52e-1571e12372c1",
    institutionName: "Westpac",
    cardName: "Altitude Qantas Black",
    url: "https://www.westpac.com.au/personal-banking/credit-cards/reward/altitude-qantas-black",
    issuer: "Mastercard",
    bonusPoints: 90000,
    minimumSpendInBonusPeriod: 6000,
    bonusPeriod: 4,
    bonusPoints2ndYear: 30000,
    earnRate: 0.75,
    annualFeeFirstYear: 370,
    annualFeeSubsequentYears: 370,
    additionalInfo:
      "$15,000 Minimum credit limit; $75,000 p.a. Minimum income required; $295 annual fee + $75 Altitude Qantas reward program fee; 2 Qantas Club Lounge Invitations per year, Complimentary Travel Insurance",
  },
  {
    id: "cb5d62be-1fb2-4a06-b7d4-0e0f52a1f935",
    institutionName: "Westpac",
    cardName: "Altitude Qantas Platinum",
    url: "https://www.westpac.com.au/personal-banking/credit-cards/reward/altitude-platinum/",
    issuer: "Mastercard",
    bonusPoints: 90000,
    minimumSpendInBonusPeriod: 3000,
    bonusPeriod: 3,
    bonusPoints2ndYear: 30000,
    earnRate: 1,
    annualFeeFirstYear: 49,
    annualFeeSubsequentYears: 175,
    additionalInfo:
      "Reduced first year card fee of $49 (reverts to $175 thereafter). Complimentary Travel Insurance",
  },
  {
    id: "5e8f24d2-2b9f-46c8-b132-1c3a9bda4d90",
    institutionName: "Bankwest",
    cardName: "Qantas Platinum",
    url: "https://www.bankwest.com.au/personal/credit-cards/qantas-frequent-flyer",
    issuer: "Mastercard",
    bonusPoints: 50000,
    minimumSpendInBonusPeriod: 3000,
    bonusPeriod: 3,
    bonusPoints2ndYear: 0,
    earnRate: 0.6,
    annualFeeFirstYear: 160,
    annualFeeSubsequentYears: 160,
    additionalInfo:
      "$3,000 minimum credit limit; No Qantas Frequent Flyer membership join fee; no Qantas points cap; No international transaction fees, Complimentary International Travel Insurance, Up to 0.6 PTS per $1 spent",
  },
  {
    id: "92d1c4f4-bb5a-48b5-a3b8-8e52f0e1d317",
    institutionName: "Qantas",
    cardName: "Premier Everyday",
    url: "https://www.qantasmoney.com/credit-cards/premier-everyday",
    issuer: "Mastercard",
    bonusPoints: 8000,
    minimumSpendInBonusPeriod: 3000,
    bonusPeriod: 3,
    bonusPoints2ndYear: 0,
    earnRate: 1,
    annualFeeFirstYear: 99,
    annualFeeSubsequentYears: 99,
    additionalInfo:
      "Uncapped Qantas Points earning potential, 0% p.a. for 12 months on Balance Transfers, with a 2% Balance Transfer fee",
  },
  {
    id: "0ac2f25d-8a34-4c38-9f31-9b02b0f5c423",
    institutionName: "Bank of Melbourne",
    cardName: "Amplify Qantas Signature",
    url: "https://www.bankofmelbourne.com.au/personal/credit-cards/frequent-flyer/amplify-signature",
    issuer: "Visa",
    bonusPoints: 80000,
    minimumSpendInBonusPeriod: 6000,
    bonusPeriod: 3,
    bonusPoints2ndYear: 0,
    earnRate: 0.75,
    annualFeeFirstYear: 370,
    annualFeeSubsequentYears: 370,
    additionalInfo:
      "$15,000 Minimum credit limit; $295 annual card fee plus an annual $75 Qantas Rewards Fee; 2 Qantas Club Lounge Invitations per year, Interstate Flight Inconvenience Insurance and Rental Vehicle Excess in Australia cover, up to 6 months complimentary cover for international travel insurance, plus extended warranty, purchase protection for up to 4 months and overseas transit accident insurance.",
  },
  {
    id: "3c6d9f48-bfb1-45d6-b3d1-56e7b4e5c11a",
    institutionName: "Bank of Melbourne",
    cardName: "Amplify Qantas Platinum",
    url: "https://www.bankofmelbourne.com.au/personal/credit-cards/frequent-flyer/amplify-platinum",
    issuer: "Visa",
    bonusPoints: 50000,
    minimumSpendInBonusPeriod: 3000,
    bonusPeriod: 3,
    bonusPoints2ndYear: 0,
    earnRate: 0.5,
    annualFeeFirstYear: 174, // Updated from $99 1st year fee + $75 Qantas opt-in fee
    annualFeeSubsequentYears: 199, // Updated from $124 p.a thereafter + $75 Qantas opt-in fee
    additionalInfo:
      "$99 1st year fee, $124 p.a thereafter + $75 p.a if you opt in to earn Qantas Points; Complimentary Travel Insurance; extended warranty and purchase protection for up to 4 months",
  },
  {
    id: "17d8a7b1-4c69-432f-a2c3-1f9c8d4f7280",
    institutionName: "BankSA",
    cardName: "Amplify Qantas Signature",
    url: "https://www.banksa.com.au/personal/credit-cards/frequent-flyer/amplify-signature",
    issuer: "Visa",
    bonusPoints: 80000,
    minimumSpendInBonusPeriod: 6000,
    bonusPeriod: 3,
    bonusPoints2ndYear: 0,
    earnRate: 0.75,
    annualFeeFirstYear: 370,
    annualFeeSubsequentYears: 370,
    additionalInfo:
      "$15,000 Minimum credit limit; $295 annual card fee plus an annual $75 Qantas Rewards Fee; 2 Qantas Club Lounge Invitations per year, Complimentary Travel Insurance; extended warranty and purchase protection for up to 4 months",
  },
  {
    id: "69e4c7d2-bc8f-4ba2-93f4-7d1a5b6f293d",
    institutionName: "BankSA",
    cardName: "Amplify Qantas Platinum",
    url: "https://www.banksa.com.au/personal/credit-cards/frequent-flyer/amplify-platinum",
    issuer: "Visa",
    bonusPoints: 50000,
    minimumSpendInBonusPeriod: 3000,
    bonusPeriod: 3,
    bonusPoints2ndYear: 0,
    earnRate: 0.5,
    annualFeeFirstYear: 174, // Updated from $99 1st year fee + $75 Qantas opt-in fee
    annualFeeSubsequentYears: 199, // Updated from $124 p.a thereafter + $75 Qantas opt-in fee
    additionalInfo:
      "$99 card fee for the first year, $124 p.a thereafter + $75 p.a if you opt in to earn Qantas Points; $6,000 Minimum credit limit; Complimentary Travel Insurance; extended warranty and purchase protection for up to 4 months",
  },
  {
    id: "2a1f6c7d-3b25-482a-bb2c-d9e1b0e4a517",
    institutionName: "Bendigo Bank",
    cardName: "Qantas Platinum Credit",
    url: "https://www.bendigobank.com.au/personal/credit-cards/qantas-platinum/",
    issuer: "Visa",
    bonusPoints: 0,
    minimumSpendInBonusPeriod: 0,
    bonusPeriod: 0,
    bonusPoints2ndYear: 0,
    earnRate: 0.6,
    annualFeeFirstYear: 149,
    annualFeeSubsequentYears: 149,
    additionalInfo:
      "Complimentary insurances, free Qantas Frequent Flyer membership; No fee for additional cardholders",
  },
  {
    id: "1d3a6e8f-2b8c-4da6-9c8d-2f7a1b3c6f42",
    institutionName: "Qudos Bank",
    cardName: "Visa Platinum",
    url: "https://www.qudosbank.com.au/products/credit-cards/visa-platinum-credit-card/",
    issuer: "Visa",
    bonusPoints: 0,
    minimumSpendInBonusPeriod: 0,
    bonusPeriod: 0,
    bonusPoints2ndYear: 0,
    earnRate: 1,
    annualFeeFirstYear: 199,
    annualFeeSubsequentYears: 199,
    additionalInfo:
      "Up to 55 days interest free period, Qantas Points Home Loan; Qantas Points Car Loan; Qantas Points Saver; Complimentary International Travel Insurance; 4.99% pa on balance transfers and purchases for your first 5 months",
  },
  {
    id: "9b2f3d6e-1a2c-4e3b-9f6d-8c7b2d5e1a42",
    institutionName: "Bankwest",
    cardName: "Qantas World Mastercard",
    url: "https://www.bankwest.com.au/personal/credit-cards/qantas-frequent-flyer",
    issuer: "Mastercard",
    bonusPoints: 50000,
    minimumSpendInBonusPeriod: 3000,
    bonusPeriod: 3,
    bonusPoints2ndYear: 0,
    earnRate: 1,
    annualFeeFirstYear: 270,
    annualFeeSubsequentYears: 270,
    additionalInfo:
      "$12,000 minimum credit limit; No Qantas Frequent Flyer membership join fee; no Qantas points cap; No international transaction fees, Complimentary International Travel Insurance",
  },
  {
    id: "5c1a8e7f-4d6b-4e2f-9c3a-7f6b2d8e1c93",
    institutionName: "BOQ Specialist",
    cardName: "Signature",
    url: "https://www.boqspecialist.com.au/personal-banking/credit-cards/signature-card",
    issuer: "Visa",
    bonusPoints: 20000,
    minimumSpendInBonusPeriod: 0,
    bonusPeriod: 2,
    bonusPoints2ndYear: 0,
    earnRate: 1,
    annualFeeFirstYear: 400,
    annualFeeSubsequentYears: 400,
    additionalInfo:
      "Only for Medical, Dental, Veterinary & Accounting specialists; bonus points when you use the credit card 10 times in the first 2 months",
  },
  {
    id: "8e7f6b1d-2c4a-4e3b-9a1c-5d7e2b8c3f61",
    institutionName: "BOQ Specialist",
    cardName: "Platinum",
    url: "https://www.boqspecialist.com.au/personal-banking/credit-cards/platinum-card",
    issuer: "Visa",
    bonusPoints: 0,
    minimumSpendInBonusPeriod: 0,
    bonusPeriod: 0,
    bonusPoints2ndYear: 0,
    earnRate: 0.5,
    annualFeeFirstYear: 150,
    annualFeeSubsequentYears: 150,
    additionalInfo:
      "Complimentary Travel Insurance, Only for Medical, Dental, Veterinary & Accounting specialists",
  },
  {
    id: "1c2b3a4d-6e7f-4b5a-9c8d-2f1a3e5b8c6d",
    institutionName: "Citi",
    cardName: "Prestige Qantas",
    url: "https://www1.citibank.com.au/credit-cards/citi-prestige-qantas",
    issuer: "Mastercard",
    bonusPoints: 150000,
    minimumSpendInBonusPeriod: 7500,
    bonusPeriod: 2,
    bonusPoints2ndYear: 0,
    earnRate: 1.5,
    annualFeeFirstYear: 749,
    annualFeeSubsequentYears: 749,
    additionalInfo:
      "Minimum credit limit $20,000; Complimentary Travel Insurance; 4th Night on Us, Unlimited airport lounge access with Priority Pass",
  },
];
