import {
  calculateCombinedScore,
  calculateResultsInBonusPeriod,
  calculateResultsOutsideOfBonusPeriod,
  isCardEligible,
  normalizeValues,
} from "src/components/calculatorUtils";
import { cards } from "src/components/cards";
import { ICalculationInput } from "./ICalculationInput";
import { ICalculationResult } from "./ICalculationResult";

export function calculatePoints3Cards(
  input: ICalculationInput
): ICalculationResult[] {
  const results: ICalculationResult[] = [];
  console.log("-----------------");
  console.log("Desired Points", input.desiredPoints);
  console.log("Monthly Spend", input.monthlyCreditCardSpend);
  console.log("Number of Cards", input.numberOfCards);
  console.log("---");

  // Main loop to process card1
  for (let i = 0; i < cards.length; i++) {
    const card1 = cards[i];
    // console.log("Checking card 1", card1);
    if (isCardEligible(card1, input.monthlyCreditCardSpend)) {
      const card1ResultsInBonusPeriod = calculateResultsInBonusPeriod(
        card1,
        input.monthlyCreditCardSpend
      );
      console.log("card1 resultsInBonusPeriod", card1ResultsInBonusPeriod);
      if (card1ResultsInBonusPeriod.totalPoints >= input.desiredPoints) {
        // We're done in the bonus period
        console.log(
          "card1 earned enough points in bonus period. We don't need 2 cards. Skipping."
        );
      } else {
        console.log("Need to add another card");
        // Let's add a 2nd card to reach desired points
        for (let j = 0; j < cards.length; j++) {
          if (i === j) {
            // Skip the same card
            continue;
          }
          const card2 = cards[j];
          console.log("Checking card 2", card2);
          if (isCardEligible(card2, input.monthlyCreditCardSpend)) {
            const card2ResultsInBonusPeriod = calculateResultsInBonusPeriod(
              card2,
              input.monthlyCreditCardSpend
            );
            console.log(
              "card2 resultsInBonusPeriod",
              card2ResultsInBonusPeriod
            );
            if (
              card1ResultsInBonusPeriod.totalPoints +
                card2ResultsInBonusPeriod.totalPoints >=
              input.desiredPoints
            ) {
              // We're done in the bonus period of card 2
              console.log(
                "card2 earned enough points in bonus period. We don't need 3 cards. Skipping."
              );
            } else {
              console.log("Need to add a third card");
              // Let's add a 3rd card to reach desired points
              for (let k = 0; k < cards.length; k++) {
                if (k === i || k === j) {
                  // Skip the same card
                  continue;
                }
                const card3 = cards[k];
                console.log("Checking card 3", card3);
                if (isCardEligible(card3, input.monthlyCreditCardSpend)) {
                  const card3ResultsInBonusPeriod =
                    calculateResultsInBonusPeriod(
                      card3,
                      input.monthlyCreditCardSpend
                    );
                  console.log(
                    "card3 resultsInBonusPeriod",
                    card3ResultsInBonusPeriod
                  );
                  if (
                    card1ResultsInBonusPeriod.totalPoints +
                      card2ResultsInBonusPeriod.totalPoints +
                      card3ResultsInBonusPeriod.totalPoints >=
                    input.desiredPoints
                  ) {
                    // We're done in the bonus period of card 3
                    console.log("card3 earned enough points in bonus period");
                    const result: ICalculationResult = {
                      cards: [card1, card2, card3],
                      bonusPoints:
                        card1.bonusPoints +
                        card2.bonusPoints +
                        card3.bonusPoints,
                      pointsEarned:
                        card1ResultsInBonusPeriod.pointsEarned +
                        card2ResultsInBonusPeriod.pointsEarned +
                        card3ResultsInBonusPeriod.pointsEarned,
                      totalPoints:
                        card1ResultsInBonusPeriod.totalPoints +
                        card2ResultsInBonusPeriod.totalPoints +
                        card3ResultsInBonusPeriod.totalPoints,
                      totalFees:
                        card1.annualFeeFirstYear +
                        card2.annualFeeFirstYear +
                        card3.annualFeeFirstYear,
                      card1Months:
                        card1ResultsInBonusPeriod.monthsNeededForBonus,
                      card2Months:
                        card2ResultsInBonusPeriod.monthsNeededForBonus,
                      card3Months:
                        card3ResultsInBonusPeriod.monthsNeededForBonus,
                      totalMonthsToDesiredPoints:
                        card1ResultsInBonusPeriod.monthsNeededForBonus +
                        card2ResultsInBonusPeriod.monthsNeededForBonus +
                        card3ResultsInBonusPeriod.monthsNeededForBonus,
                      totalSpend:
                        card1ResultsInBonusPeriod.spend +
                        card2ResultsInBonusPeriod.spend +
                        card3ResultsInBonusPeriod.spend,
                      normalizedFees: 0, // Placeholder
                      normalizedMonths: 0, // Placeholder
                      combinedScore: 0, // Placeholder
                    };
                    console.log("card1 + card 2 + card 3 result", result);
                    results.push(result);
                  } else {
                    console.log(
                      "We only have " +
                        (card1ResultsInBonusPeriod.totalPoints +
                          card2ResultsInBonusPeriod.totalPoints +
                          card3ResultsInBonusPeriod.totalPoints) +
                        " points of " +
                        input.desiredPoints +
                        ". Card3 needs to earn more points outside of bonus period"
                    );
                    const remainingPoints =
                      input.desiredPoints -
                      card1ResultsInBonusPeriod.totalPoints -
                      card2ResultsInBonusPeriod.totalPoints -
                      card3ResultsInBonusPeriod.totalPoints;
                    const resultsOutsideOfBonusPeriod =
                      calculateResultsOutsideOfBonusPeriod(
                        card3,
                        card1ResultsInBonusPeriod.monthsNeededForBonus +
                          card2ResultsInBonusPeriod.monthsNeededForBonus +
                          card3ResultsInBonusPeriod.monthsNeededForBonus,
                        remainingPoints,
                        input.monthlyCreditCardSpend
                      );
                    console.log(
                      "resultsOutsideOfBonusPeriod",
                      resultsOutsideOfBonusPeriod
                    );
                    const result: ICalculationResult = {
                      cards: [card1, card2, card3],
                      bonusPoints:
                        card1.bonusPoints +
                        card2.bonusPoints +
                        card3.bonusPoints,
                      pointsEarned:
                        card1ResultsInBonusPeriod.pointsEarned +
                        card2ResultsInBonusPeriod.pointsEarned +
                        card3ResultsInBonusPeriod.pointsEarned +
                        resultsOutsideOfBonusPeriod.points,
                      totalPoints:
                        card1ResultsInBonusPeriod.totalPoints +
                        card2ResultsInBonusPeriod.totalPoints +
                        card3ResultsInBonusPeriod.totalPoints +
                        resultsOutsideOfBonusPeriod.points,
                      totalFees:
                        card1.annualFeeFirstYear +
                        card2.annualFeeFirstYear +
                        card3.annualFeeFirstYear +
                        resultsOutsideOfBonusPeriod.fees,
                      card1Months:
                        card1ResultsInBonusPeriod.monthsNeededForBonus,
                      card2Months:
                        card2ResultsInBonusPeriod.monthsNeededForBonus,
                      card3Months:
                        card3ResultsInBonusPeriod.monthsNeededForBonus +
                        resultsOutsideOfBonusPeriod.monthsToDesiredPoints,
                      totalMonthsToDesiredPoints:
                        card1ResultsInBonusPeriod.monthsNeededForBonus +
                        card2ResultsInBonusPeriod.monthsNeededForBonus +
                        card3ResultsInBonusPeriod.monthsNeededForBonus +
                        resultsOutsideOfBonusPeriod.monthsToDesiredPoints,
                      totalSpend:
                        card1ResultsInBonusPeriod.spend +
                        card2ResultsInBonusPeriod.spend +
                        card3ResultsInBonusPeriod.spend +
                        resultsOutsideOfBonusPeriod.spend,
                      normalizedFees: 0, // Placeholder
                      normalizedMonths: 0, // Placeholder
                      combinedScore: 0, // Placeholder
                    };
                    console.log("card1 + card 2 + card 3 result", result);
                    results.push(result);
                  }
                } else {
                  console.log("card3 is not eligible");
                }
              }
            }
          } else {
            console.log("card2 is not eligible");
          }
        }
      }
    }
  }

  // Normalize values and calculate combined score
  const normalizedResults = normalizeValues(results);
  const scoredResults = calculateCombinedScore(normalizedResults);

  return scoredResults;
}
