import { cards } from "src/components/cards";
import {
  calculateCombinedScore,
  calculateResultsInBonusPeriod,
  calculateResultsOutsideOfBonusPeriod,
  isCardEligible,
  normalizeValues,
} from "./calculatorUtils";
import { ICalculationInput } from "./ICalculationInput";
import { ICalculationResult } from "./ICalculationResult";

export function calculatePoints1Card(
  input: ICalculationInput
): ICalculationResult[] {
  const results: ICalculationResult[] = [];
  console.log("-----------------");
  console.log("Desired Points", input.desiredPoints);
  console.log("Monthly Spend", input.monthlyCreditCardSpend);
  console.log("Number of Cards", input.numberOfCards);
  console.log("---");

  for (let i = 0; i < cards.length; i++) {
    const card1 = cards[i];
    console.log("Checking", card1);
    if (isCardEligible(card1, input.monthlyCreditCardSpend)) {
      const card1ResultsInBonusPeriod = calculateResultsInBonusPeriod(
        card1,
        input.monthlyCreditCardSpend
      );
      console.log("card1 resultsInBonusPeriod", card1ResultsInBonusPeriod);
      if (card1ResultsInBonusPeriod.totalPoints >= input.desiredPoints) {
        console.log("card1 earned enough points in bonus period");
        const result: ICalculationResult = {
          cards: [card1],
          bonusPoints: card1.bonusPoints,
          pointsEarned: card1ResultsInBonusPeriod.pointsEarned,
          totalPoints: card1ResultsInBonusPeriod.totalPoints,
          totalFees: card1.annualFeeFirstYear,
          card1Months: card1ResultsInBonusPeriod.monthsNeededForBonus,
          card2Months: 0,
          card3Months: 0,
          totalMonthsToDesiredPoints:
            card1ResultsInBonusPeriod.monthsNeededForBonus,
          totalSpend: card1ResultsInBonusPeriod.spend,
        };
        console.log("card1 result", result);
        results.push(result);
      } else {
        console.log("card1 needs to earn more points outside of bonus period");
        const remainingPoints =
          input.desiredPoints - card1ResultsInBonusPeriod.totalPoints;
        const resultsOutsideOfBonusPeriod =
          calculateResultsOutsideOfBonusPeriod(
            card1,
            card1ResultsInBonusPeriod.monthsNeededForBonus,
            remainingPoints,
            input.monthlyCreditCardSpend
          );
        console.log("resultsOutsideOfBonusPeriod", resultsOutsideOfBonusPeriod);
        const result: ICalculationResult = {
          cards: [card1],
          bonusPoints: card1.bonusPoints,
          pointsEarned:
            card1ResultsInBonusPeriod.pointsEarned +
            resultsOutsideOfBonusPeriod.points,
          totalPoints:
            card1ResultsInBonusPeriod.totalPoints +
            resultsOutsideOfBonusPeriod.points,
          totalFees:
            card1.annualFeeFirstYear + resultsOutsideOfBonusPeriod.fees,
          card1Months:
            card1ResultsInBonusPeriod.monthsNeededForBonus +
            resultsOutsideOfBonusPeriod.monthsToDesiredPoints,
          card2Months: 0,
          card3Months: 0,
          totalMonthsToDesiredPoints:
            card1ResultsInBonusPeriod.monthsNeededForBonus +
            resultsOutsideOfBonusPeriod.monthsToDesiredPoints,
          totalSpend:
            card1ResultsInBonusPeriod.spend + resultsOutsideOfBonusPeriod.spend,
        };
        console.log("card1 result", result);
        results.push(result);
      }
    } else {
      console.log("card1 is not eligible");
    }
  }

  // Normalize values and calculate combined score
  const normalizedResults = normalizeValues(results);
  const scoredResults = calculateCombinedScore(normalizedResults);

  return scoredResults;
}
