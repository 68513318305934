import React, { useState, useEffect } from "react";
import { NUMBER_OF_TOP_RESULTS } from "src/components/calculatorUtils";
import { ICalculationResult } from "./ICalculationResult";
import styles from "src/components/ResultsTable2Cards.module.css";
import { ExternalLink } from "react-feather";

interface ResultsTableProps {
  results: ICalculationResult[];
}

const ResultsTable2Cards: React.FC<ResultsTableProps> = ({ results }) => {
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const [sortedResults, setSortedResults] = useState<ICalculationResult[]>([]);
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  useEffect(() => {
    const sorted = [...results].sort((a, b) => {
      let aValue: any;
      let bValue: any;

      if (sortColumn === "cardsName") {
        const card1AName =
          `${a.cards[0].institutionName} ${a.cards[0].cardName}`.toLowerCase();
        const card2AName =
          `${a.cards[1].institutionName} ${a.cards[1].cardName}`.toLowerCase();
        aValue = `${card1AName} - ${card2AName}`;
        const card1BName =
          `${b.cards[0].institutionName} ${b.cards[0].cardName}`.toLowerCase();
        const card2BName =
          `${b.cards[1].institutionName} ${b.cards[1].cardName}`.toLowerCase();
        bValue = `${card1BName} - ${card2BName}`;
      } else {
        aValue = a[sortColumn as keyof ICalculationResult];
        bValue = b[sortColumn as keyof ICalculationResult];
      }
      if (sortOrder === "asc") {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });
    setSortedResults(sorted);
  }, [results, sortColumn, sortOrder]);

  const handleSort = (column: string) => {
    let newSortOrder: "asc" | "desc" = "asc";
    if (sortColumn === column && sortOrder === "asc") {
      newSortOrder = "desc";
    }
    setSortColumn(column);
    setSortOrder(newSortOrder);
  };

  const renderSortIcon = (column: string) => {
    if (sortColumn === column) {
      return sortOrder === "asc" ? " ▽▲" : " ▼△";
    }
    return " ▽△";
  };

  const handleRowClick = (id: string) => {
    if (expandedRows.includes(id)) {
      setExpandedRows(expandedRows.filter((rowId) => rowId !== id));
    } else {
      setExpandedRows([...expandedRows, id]);
    }
  };

  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th
              onClick={() => handleSort("cardsName")}
              className={styles.sortable}
            >
              Cards {renderSortIcon("cardsName")}
            </th>
            <th
              onClick={() => handleSort("totalMonthsToDesiredPoints")}
              className={styles.sortable}
            >
              <span style={{ whiteSpace: "nowrap" }}>
                Months{renderSortIcon("totalMonthsToDesiredPoints")}
              </span>
            </th>
            <th
              onClick={() => handleSort("totalFees")}
              className={styles.sortable}
            >
              Fees {renderSortIcon("totalFees")}
            </th>
            <th
              onClick={() => handleSort("combinedScore")}
              className={styles.sortable}
            >
              Overall Score {renderSortIcon("combinedScore")}
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedResults.slice(0, NUMBER_OF_TOP_RESULTS).map((result) => (
            <React.Fragment key={result.cards[0].id + result.cards[1].id}>
              <tr
                className={styles.row}
                onClick={() =>
                  handleRowClick(result.cards[0].id + result.cards[1].id)
                }
              >
                <td data-label="Card" className={styles.cardName}>
                  {expandedRows.includes(
                    result.cards[0].id + result.cards[1].id
                  )
                    ? "▼ "
                    : "▶ "}
                  {result.cards[0].institutionName} {result.cards[0].cardName}{" "}
                  <a
                    href={result.cards[0].url}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <ExternalLink size={"15"} />
                  </a>
                  {" - "}
                  {result.cards[1].institutionName +
                    " " +
                    result.cards[1].cardName}{" "}
                  <a
                    href={result.cards[1].url}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <ExternalLink size={"15"} />
                  </a>
                </td>
                <td
                  data-label="Months to Earn Points"
                  className={styles.months}
                >
                  {result.totalMonthsToDesiredPoints}
                </td>
                <td data-label="Total Fees" className={styles.fees}>
                  ${result.totalFees}.00
                </td>
                <td data-label="Overall Score" className={styles.score}>
                  {result.combinedScore}
                </td>
              </tr>
              {expandedRows.includes(
                result.cards[0].id + result.cards[1].id
              ) && (
                <tr
                  key={`${result.cards[0].id}-details`}
                  className={`${styles.detailsRow} ${styles.expandableRow}`}
                >
                  <td colSpan={4}>
                    <div className={styles.detailsContainer}>
                      <h3>
                        {result.cards[0].institutionName}{" "}
                        {result.cards[0].cardName}
                      </h3>
                      <p>
                        Use card for {result.card1Months} months to reach bonus
                        points. Cancel card or stop using it.
                      </p>
                      <p>
                        <b>Bonus Points:</b> {result.cards[0].bonusPoints}
                      </p>
                      <p>
                        <b>Minimum Spend:</b> $
                        {result.cards[0].minimumSpendInBonusPeriod}.00
                      </p>
                      <p>
                        <b>Months to Spend:</b> {result.cards[0].bonusPeriod}
                      </p>
                      <p
                        className={
                          result.totalMonthsToDesiredPoints < 12
                            ? styles.italics
                            : undefined
                        }
                      >
                        <b>Bonus Points 2nd Year:</b>{" "}
                        {result.cards[0].bonusPoints2ndYear}
                      </p>
                      <p>
                        <b>Earn Rate:</b> {result.cards[0].earnRate} pt/$
                      </p>
                      <p>
                        <b>Annual Fee 1st Year:</b> $
                        {result.cards[0].annualFeeFirstYear}.00
                      </p>

                      <p
                        className={
                          result.totalMonthsToDesiredPoints < 12
                            ? styles.italics
                            : undefined
                        }
                      >
                        <b>Annual Fee Subsequent Years:</b> $
                        {result.cards[0].annualFeeSubsequentYears}.00
                      </p>
                      <p>
                        <b>Additional Info:</b> {result.cards[0].additionalInfo}
                      </p>
                      <h3>
                        {result.cards[1].institutionName}{" "}
                        {result.cards[1].cardName}
                      </h3>
                      <p>
                        Use card for {result.card2Months} months to reach bonus
                        points and earn remaining points if needed.
                      </p>
                      <p>
                        <b>Initial Bonus Points:</b>{" "}
                        {result.cards[1].bonusPoints}
                      </p>
                      <p>
                        <b>Minimum Spend:</b> $
                        {result.cards[1].minimumSpendInBonusPeriod}.00
                      </p>
                      <p>
                        <b>Months to Spend:</b> {result.cards[1].bonusPeriod}
                      </p>
                      <p
                        className={
                          result.totalMonthsToDesiredPoints < 12
                            ? styles.italics
                            : undefined
                        }
                      >
                        <b>Bonus Points 2nd Year:</b>{" "}
                        {result.cards[1].bonusPoints2ndYear}
                      </p>
                      <p>
                        <b>Earn Rate:</b> {result.cards[1].earnRate} pt/$
                      </p>
                      <p>
                        <b>Annual Fee 1st Year:</b> $
                        {result.cards[1].annualFeeFirstYear}.00
                      </p>

                      <p
                        className={
                          result.totalMonthsToDesiredPoints < 12
                            ? styles.italics
                            : undefined
                        }
                      >
                        <b>Annual Fee Subsequent Years:</b> $
                        {result.cards[1].annualFeeSubsequentYears}.00
                      </p>
                      <p>
                        <b>Additional Info:</b> {result.cards[1].additionalInfo}
                      </p>
                      <h3>Totals</h3>
                      <p>
                        <b>Points Earned Through Bonuses:</b>{" "}
                        {result.bonusPoints}
                      </p>

                      <p>
                        <b>Points Earned Through Spend:</b>{" "}
                        {result.pointsEarned}
                      </p>
                      <p>
                        <b>Total Points:</b> {result.totalPoints}
                      </p>
                      <p>
                        <b>Total Fees:</b> ${result.totalFees}.00
                      </p>
                      <p>
                        <b>Total Spend Needed:</b> ${result.totalSpend}.00
                      </p>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ResultsTable2Cards;
