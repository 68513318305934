import { ICalculationResult } from "src/components/ICalculationResult";
import { ICreditCard } from "src/components/ICreditCard";

const FEES_WEIGHT = 0.5; // Weight for the fees component
const MONTHS_WEIGHT = 0.5; // Weight for the months component

export const NUMBER_OF_TOP_RESULTS = 10;

export function normalizeValues(results: ICalculationResult[]) {
  const maxFees = Math.max(...results.map((result) => result.totalFees));
  const maxMonths = Math.max(
    ...results.map((result) => result.totalMonthsToDesiredPoints)
  );

  return results.map((result) => ({
    ...result,
    normalizedFees: result.totalFees / maxFees,
    normalizedMonths: result.totalMonthsToDesiredPoints / maxMonths,
  }));
}

export function calculateCombinedScore(results: ICalculationResult[]) {
  return results.map((result) => ({
    ...result,
    //score form 0-100
    combinedScore:
      1000 -
      Math.round(
        1000 *
          (result.normalizedFees! * FEES_WEIGHT +
            result.normalizedMonths! * MONTHS_WEIGHT)
      ),
  }));
}

export const isCardEligible = (
  card: ICreditCard,
  monthlySpend: number
): boolean => {
  const spendBonusPeriod = card.bonusPeriod! * monthlySpend;
  return card.minimumSpendInBonusPeriod! <= spendBonusPeriod;
};

export const calculateResultsInBonusPeriod = (
  card: ICreditCard,
  monthlySpend: number
) => {
  const monthsNeededForBonus = Math.ceil(
    card.minimumSpendInBonusPeriod! / monthlySpend
  );
  const spendInBonusPeriod = monthsNeededForBonus * monthlySpend;
  const pointsEarned = spendInBonusPeriod * card.earnRate;
  const totalPoints = pointsEarned + card.bonusPoints;

  return {
    monthsNeededForBonus: monthsNeededForBonus,
    pointsEarned: pointsEarned,
    totalPoints: totalPoints,
    spend: spendInBonusPeriod,
  };
};

export const calculateResultsOutsideOfBonusPeriod = (
  card: ICreditCard,
  monthsNeededForBonus: number,
  remainingPoints: number,
  monthlySpend: number
) => {
  let points: number = 0;
  let monthsToDesiredPoints = 0;
  let fees = 0;

  monthsToDesiredPoints = Math.ceil(
    remainingPoints / (monthlySpend * card.earnRate)
  );
  points = monthsToDesiredPoints * monthlySpend * card.earnRate;

  if (monthsToDesiredPoints + monthsNeededForBonus > 12) {
    if (card.bonusPoints2ndYear) {
      const revisedPoints = remainingPoints - card.bonusPoints2ndYear;
      monthsToDesiredPoints = Math.ceil(
        revisedPoints / (monthlySpend * card.earnRate)
      );

      monthsToDesiredPoints = Math.max(monthsToDesiredPoints, 13);
      points =
        monthsToDesiredPoints * monthlySpend * card.earnRate +
        card.bonusPoints2ndYear;
    }
    fees =
      Math.ceil((monthsNeededForBonus + monthsToDesiredPoints - 12) / 12) *
      card.annualFeeSubsequentYears;
  }

  const spend = monthsToDesiredPoints * monthlySpend;

  return {
    points: points,
    spend: spend,
    fees: fees,
    monthsToDesiredPoints: monthsToDesiredPoints,
  };
};
