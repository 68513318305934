import React, { useState } from "react";
import { ChevronDown, ChevronUp } from "react-feather";
import styles from "./PointsCalculatorForm.module.css";

interface PointsCalculatorFormProps {
  desiredPoints: string;
  setDesiredPoints: React.Dispatch<React.SetStateAction<string>>;
  monthlySpend: string;
  setMonthlySpend: React.Dispatch<React.SetStateAction<string>>;
  destination: string;
  setDestination: React.Dispatch<React.SetStateAction<string>>;
  numberOfCards: string;
  setNumberOfCards: React.Dispatch<React.SetStateAction<string>>;
  runCalculation: () => void;
}

const PointsCalculatorForm: React.FC<PointsCalculatorFormProps> = ({
  desiredPoints,
  setDesiredPoints,
  monthlySpend,
  setMonthlySpend,
  destination,
  setDestination,
  numberOfCards,
  setNumberOfCards,
  runCalculation,
}) => {
  const [isSectionVisible, setIsSectionVisible] = useState(false);

  const toggleSectionVisibility = () => {
    setIsSectionVisible(!isSectionVisible);
  };

  return (
    <div className={styles.formContainer}>
      <h1 className={styles.heading}>
        <img src="/logo.png" alt="Logo" /> Qantas Points Calculator
      </h1>
      <p>
        Use this calculator to find out how long it will take you to earn your
        desired Qantas Points by getting a points earning credit card.
      </p>
      <div className={isSectionVisible ? styles.expandableRow : undefined}>
        {isSectionVisible && (
          <div>
            <p>
              Enter the number of points you want to earn and how much you
              expect to spend on your card each month, and easily see how long
              it will take you to earn your desired points, and how many fees
              you'll need to pay.
            </p>
            <p>
              To find out how many Qantas Points you need for a flight go to the{" "}
              <a
                href={
                  "https://www.qantas.com/au/en/frequent-flyer/calculators.html#usepoints"
                }
                target={"_blank"}
                rel="noreferrer"
              >
                Qantas Points Calculator
              </a>
              .
            </p>
            <p>Example:</p>
            <ul>
              <li>
                Return flight Melbourne Tokyo, Japan Economy Class starts from
                63,000 points, average is
              </li>
              <li>
                Return flight Melbourne Queenstown, New Zealand Economy Class
                starts from 32,400 points
              </li>
              <li>
                Return flight Melbourne Rome, Italy Economy Class starts from
                110,400 points
              </li>
              <li>
                Return flight Melbourne Tokyo Business Class starts from 164,000
                points
              </li>
              <li>
                Return flight Melbourne Queenstown, New Zealand Business Class
                starts from 62,000 points
              </li>
              <li>
                Return flight Melbourne Rome, Italy Business Class starts from
                289,200 points
              </li>
            </ul>
          </div>
        )}
        <div className={styles.chevron}>
          {isSectionVisible ? (
            <ChevronUp size={"18"} onClick={toggleSectionVisibility} />
          ) : (
            <ChevronDown size={"18"} onClick={toggleSectionVisibility} />
          )}
        </div>
      </div>
      <hr />
      <h2 className={styles.heading}>Data Input</h2>

      <div className={styles.formGroup}>
        <label>How many points do you need to earn?</label>
        <input
          type="range"
          min="0"
          max="1000000"
          step="5000"
          value={desiredPoints}
          onChange={(e) => setDesiredPoints(e.target.value)}
        />
        <input
          type="text"
          value={desiredPoints}
          onChange={(e) => setDesiredPoints(e.target.value)}
        />
      </div>
      <div className={styles.formGroup}>
        <label>
          How much do you expect to charge monthly on your Qantas Points credit
          card?
        </label>
        <input
          type="range"
          min="0"
          max="20000"
          step="100"
          value={monthlySpend}
          onChange={(e) => setMonthlySpend(e.target.value)}
        />
        <input
          type="text"
          value={monthlySpend}
          onChange={(e) => setMonthlySpend(e.target.value)}
        />
      </div>
      <div className={styles.formGroup}>
        <label>
          How many credit cards are you considering to get to reach your points?
        </label>
        <input
          type="range"
          min="1"
          max="3"
          value={numberOfCards}
          onChange={(e) => setNumberOfCards(e.target.value)}
        />
        <input
          type="text"
          value={numberOfCards}
          onChange={(e) => setNumberOfCards(e.target.value)}
        />
      </div>
      <button className={styles.button} onClick={runCalculation}>
        Calculate
      </button>
    </div>
  );
};

export default PointsCalculatorForm;
