import { cards } from "src/components/cards";
import {
  calculateCombinedScore,
  calculateResultsInBonusPeriod,
  calculateResultsOutsideOfBonusPeriod,
  isCardEligible,
  normalizeValues,
} from "./calculatorUtils";
import { ICalculationInput } from "./ICalculationInput";
import { ICalculationResult } from "./ICalculationResult";

export function calculatePoints2Cards(
  input: ICalculationInput
): ICalculationResult[] {
  const results: ICalculationResult[] = [];
  console.log("-----------------");
  console.log("Desired Points", input.desiredPoints);
  console.log("Monthly Spend", input.monthlyCreditCardSpend);
  console.log("Number of Cards", input.numberOfCards);
  console.log("---");

  // Main loop to process card1
  for (let i = 0; i < cards.length; i++) {
    const card1 = cards[i];
    console.log("Checking card 1", card1);
    if (isCardEligible(card1, input.monthlyCreditCardSpend)) {
      const card1ResultsInBonusPeriod = calculateResultsInBonusPeriod(
        card1,
        input.monthlyCreditCardSpend
      );
      console.log("card1 resultsInBonusPeriod", card1ResultsInBonusPeriod);
      if (card1ResultsInBonusPeriod.totalPoints >= input.desiredPoints) {
        // We're done in the bonus period
        console.log(
          "card1 earned enough points in bonus period. We don't need 2 cards. Skipping."
        );
        const result: ICalculationResult = {
          cards: [card1],
          bonusPoints: card1.bonusPoints,
          pointsEarned: card1ResultsInBonusPeriod.pointsEarned,
          totalPoints: card1ResultsInBonusPeriod.totalPoints,
          totalFees: card1.annualFeeFirstYear,
          card1Months: card1ResultsInBonusPeriod.monthsNeededForBonus,
          card2Months: 0,
          card3Months: 0,
          totalMonthsToDesiredPoints:
            card1ResultsInBonusPeriod.monthsNeededForBonus,
          totalSpend: card1ResultsInBonusPeriod.spend,
          normalizedFees: 0, // Placeholder
          normalizedMonths: 0, // Placeholder
          combinedScore: 0, // Placeholder
        };
        console.log("card1 result", result);
        // results.push(result);
      } else {
        console.log("Need to add another card");
        // Let's add a 2nd card to reach desired points
        for (let j = 0; j < cards.length; j++) {
          if (i === j) {
            // Skip the same card
            continue;
          }
          const card2 = cards[j];
          console.log("Checking card 2", card2);
          if (isCardEligible(card2, input.monthlyCreditCardSpend)) {
            const card2ResultsInBonusPeriod = calculateResultsInBonusPeriod(
              card2,
              input.monthlyCreditCardSpend
            );
            console.log(
              "card2 resultsInBonusPeriod",
              card2ResultsInBonusPeriod
            );
            if (
              card1ResultsInBonusPeriod.totalPoints +
                card2ResultsInBonusPeriod.totalPoints >=
              input.desiredPoints
            ) {
              // We're done in the bonus period of card 2
              console.log("card2 earned enough points in bonus period");
              const result: ICalculationResult = {
                cards: [card1, card2],
                bonusPoints: card1.bonusPoints + card2.bonusPoints,
                pointsEarned:
                  card1ResultsInBonusPeriod.pointsEarned +
                  card2ResultsInBonusPeriod.pointsEarned,
                totalPoints:
                  card1ResultsInBonusPeriod.totalPoints +
                  card2ResultsInBonusPeriod.totalPoints,
                totalFees: card1.annualFeeFirstYear + card2.annualFeeFirstYear,
                card1Months: card1ResultsInBonusPeriod.monthsNeededForBonus,
                card2Months: card2ResultsInBonusPeriod.monthsNeededForBonus,
                card3Months: 0,
                totalMonthsToDesiredPoints:
                  card1ResultsInBonusPeriod.monthsNeededForBonus +
                  card2ResultsInBonusPeriod.monthsNeededForBonus,
                totalSpend:
                  card1ResultsInBonusPeriod.spend +
                  card2ResultsInBonusPeriod.spend,
                normalizedFees: 0, // Placeholder
                normalizedMonths: 0, // Placeholder
                combinedScore: 0, // Placeholder
              };
              console.log("card1 + card 2 result", result);
              results.push(result);
            } else {
              console.log(
                "We only have " +
                  (card1ResultsInBonusPeriod.totalPoints +
                    card2ResultsInBonusPeriod.totalPoints) +
                  " points of " +
                  input.desiredPoints +
                  ". Card2 needs to earn more points outside of bonus period"
              );
              const remainingPoints =
                input.desiredPoints -
                card1ResultsInBonusPeriod.totalPoints -
                card2ResultsInBonusPeriod.totalPoints;
              const cardToEarnResultsOutsideOfBonusPeriod =
                calculateResultsOutsideOfBonusPeriod(
                  card2,
                  card1ResultsInBonusPeriod.monthsNeededForBonus +
                    card2ResultsInBonusPeriod.monthsNeededForBonus,
                  remainingPoints,
                  input.monthlyCreditCardSpend
                );
              console.log(
                "resultsOutsideOfBonusPeriod",
                cardToEarnResultsOutsideOfBonusPeriod
              );
              const result: ICalculationResult = {
                cards: [card1, card2],
                bonusPoints: card1.bonusPoints + card2.bonusPoints,
                pointsEarned:
                  card1ResultsInBonusPeriod.pointsEarned +
                  card2ResultsInBonusPeriod.pointsEarned +
                  cardToEarnResultsOutsideOfBonusPeriod.points,
                totalPoints:
                  card1ResultsInBonusPeriod.totalPoints +
                  card2ResultsInBonusPeriod.totalPoints +
                  cardToEarnResultsOutsideOfBonusPeriod.points,
                totalFees:
                  card1.annualFeeFirstYear +
                  card2.annualFeeFirstYear +
                  cardToEarnResultsOutsideOfBonusPeriod.fees,
                card1Months: card1ResultsInBonusPeriod.monthsNeededForBonus,
                card2Months:
                  card2ResultsInBonusPeriod.monthsNeededForBonus +
                  cardToEarnResultsOutsideOfBonusPeriod.monthsToDesiredPoints,
                card3Months: 0,
                totalMonthsToDesiredPoints:
                  card1ResultsInBonusPeriod.monthsNeededForBonus +
                  card2ResultsInBonusPeriod.monthsNeededForBonus +
                  cardToEarnResultsOutsideOfBonusPeriod.monthsToDesiredPoints,
                totalSpend:
                  card1ResultsInBonusPeriod.spend +
                  card2ResultsInBonusPeriod.spend +
                  cardToEarnResultsOutsideOfBonusPeriod.spend,
                normalizedFees: 0, // Placeholder
                normalizedMonths: 0, // Placeholder
                combinedScore: 0, // Placeholder
              };
              console.log("card1 + card 2result", result);
              results.push(result);
            }
          } else {
            console.log("card2 is not eligible");
          }
        }
      }
    }
  }

  // Normalize values and calculate combined score
  const normalizedResults = normalizeValues(results);
  const scoredResults = calculateCombinedScore(normalizedResults);

  return scoredResults;
}
